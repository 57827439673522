import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//import List from 'react-content-loader'

//import clsx from 'clsx';
import Config from "../../AppConfig";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
//import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
//import Avatar from '@material-ui/core/Avatar';
//import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
//import FavoriteIcon from '@material-ui/icons/Favorite';
//import ShareIcon from '@material-ui/icons/Share';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 645,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

export default function Post(props) {

    const [expanded] = React.useState(true);

    /*const handleExpandClick = () => {
        setExpanded(!expanded);
    };*/
    const classes = useStyles();
    const {post} = props;


    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{minHeight: '100vh'}}
        ><Grid item>
            {post ? (
                <Card className={classes.card}>
                    <CardHeader
                        /*avatar={
                            <Avatar aria-label="recipe" className={classes.avatar}>
                                R
                            </Avatar>
                        }*/
                        /*action={
                            <IconButton aria-label="settings">
                                <MoreVertIcon/>
                            </IconButton>
                        }*/
                        title={post.title}
                        subheader={Config.formatDate(new Date(post.updatedAt))}
                    />
                    <CardMedia
                        className={classes.media}
                        image= {post.image ? post.image : "https://kslides.s3.eu-central-1.amazonaws.com/K-thumb1_web.jpg"}
                        title={post.imageText}
                    />
                    <CardContent>
                        {/*<Typography variant="body2" color="textSecondary" component="p">
                            {post.blogID}
                        </Typography>*/}
                    </CardContent>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography paragraph dangerouslySetInnerHTML={{ __html:post.content}}></Typography>
                        </CardContent>
                    </Collapse>
                   {/* <CardActions disableSpacing>
                        <IconButton aria-label="add to favorites">
                            <FavoriteIcon/>
                        </IconButton>
                        <IconButton aria-label="share">
                            <ShareIcon/>
                        </IconButton>
                         <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                    </CardActions>*/}
                </Card>
            ) : (
                null
            )}
        </Grid>
        </Grid>
    );
}


Post.propTypes = {
  post: PropTypes.object
};
