import React from 'react';
import PropTypes from 'prop-types';
var Carousel = require('react-responsive-carousel').Carousel;


function doNothing(){};

export default function MainCarousel(props) {
  const { slides } = props;

  return (
    <Carousel showArrows={true} showIndicators={true} showThumbs={false} onChange={doNothing} onClickItem={doNothing} autoPlay={true} interval={5000} stopOnHover={true} infiniteLoop={true} onClickThumb={doNothing}>
                {slides.map((slide,index) => (
                <div key={index}>
                    <img src={slide.src} alt="slider resource"/>
                <h1 className="legend">{slide.title}</h1>
                <a href={slide.href} className="legendButton" style = {{fontFamily:slide.fontFamily,fontSize:slide.fontSize, top : slide.top, bottom : slide.bottom, right: slide.right, left: slide.left }}>{slide.label}</a>
              </div>
                ))}
            </Carousel>
  );
}

MainCarousel.propTypes = {
  slides: PropTypes.array,
};
