import React from "react";
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { withStyles } from '@material-ui/core/styles';

import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AppBar from '@material-ui/core/AppBar';
import Tabs  from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import JSONInput from 'react-json-editor-ajrm';
import locale    from 'react-json-editor-ajrm/locale/fr';
import { Editor } from '@tinymce/tinymce-react';

import { API, Auth } from 'aws-amplify';
import {toast} from "react-toastify";
import {getBlog as GetBlog,listSiteSettings} from "../../graphql/queries";
import {createPost,deletePost, updatePost, updateSiteSetting} from "../../graphql/mutations";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        style={{ maxWidth: "1400px", maxHeight: "100%" }}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
const styles = (theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

class AdminPage extends React.Component {


    handleObjectChange = (event) => {
        this.setState({ currentObject: event.target.value });
        //case when posts
        if(event.target.value.content){
            this.setState({ currentContent: event.target.value.content });
            if(event.target.value){
                let temp = JSON.parse(JSON.stringify(event.target.value))
                delete temp.content;
                this.setState({currentJson : temp})
            }
        }
        else{
            if(event.target.value){
                let temp = JSON.parse(JSON.stringify(event.target.value))
                temp.value = JSON.parse(temp.value);
                this.setState({ currentContent: temp.value.content });
                delete temp.value.content
                this.setState({currentJson : temp})
            }
        }

    };

    constructor(props){
        super(props);
        this.state = {
            value : 0,
            isReady : false,
            user : {},
            allObjects : [],
            currentObject : {},
            currentJson : {},
            currentContent : ""
        };
        Auth.currentAuthenticatedUser().then(user=>{
            //console.log( user );
            this.setState({"user":user})
            if(user.signInUserSession.accessToken.payload["cognito:groups"].indexOf("Admins")!== -1){
                //user is Admin
                this.setState({"isReady":true});
                //call first tab
                this.tab0();
            }
        }).catch(err => {
            console.log(err);
            toast.error(err.message || "Error getting user");
        });
    }

    async tab0() {
        //console.log(Storage.get('IMG_1234.JPG',{ level: 'public' }));
        try {
            const blogs = await API.graphql({query:GetBlog,variables:{"id":"news-id"},authMode:"API_KEY"});

            //console.log('posts:', blogs.data.getBlog.posts.items)
            let posts = blogs.data.getBlog.posts.items;
            this.setState({allObjects: posts});
            this.setState({currentObject : {}});
            this.setState({currentJson : {}});
            this.setState({currentContent : ""});

        } catch (err) {
            console.log('error fetching blogs...', err)
        }
    }
    
    async tab1(){
        try {
        const allSettings = await API.graphql({query:listSiteSettings,variables:{},authMode:"API_KEY"});

        let settings = allSettings.data.listSiteSettings.items;
            this.setState({allObjects: settings});
            this.setState({currentObject : {}})
            this.setState({currentJson : {}});
            this.setState({currentContent : ""});
    } catch (err) {
        console.log('error about_us settings...', err)
    }
    }

    handleChange = (event, newValue) => {
        this.setState({value : newValue});
        switch (newValue) {
            case 0:
                this.tab0();
                break;
            case 1:
                this.tab1();
                break;
            default:
                break;
        }
      };
    jsonChanged = (json)=>{
        if(json.jsObject)
            this.setState({ currentJson: json.jsObject });
        //this.setState({currentContent : json.jsObject ? json.jsObject.content : ""})
    };
    handleEditorChange = (html)=>{
        this.setState({currentContent : html})
        if(this.state.currentJson){
            let temp = JSON.parse(JSON.stringify(this.state.currentJson))
            temp.content = html;
            delete temp.content;
            this.setState({currentJson : temp})
        }
    };

    saveNews = async () =>{
        try {
            await API.graphql({query: updatePost, variables: {input: {...this.state.currentJson,content : this.state.currentContent}}, authMode: "AMAZON_COGNITO_USER_POOLS"});
            toast.success("saved");
        }catch (e) {
            console.log(e);
            toast.error(e.message || "Error updating News");
        }
    }

    createNews  = async () =>{

        try {
            await API.graphql({query: createPost, variables: {input: {...this.state.currentJson,content : this.state.currentContent}}, authMode: "AMAZON_COGNITO_USER_POOLS"});
            toast.success("Created");
        }catch (e) {
            console.log(e);
            toast.error(e.message || "Error Creating News");
        }
    }

    deleteNews  = async () =>{

        try {
            const deleted = await API.graphql({query: deletePost, variables: {input: {id : this.state.currentJson.id}}, authMode: "AMAZON_COGNITO_USER_POOLS"});
            if(deleted.errors)
                toast.error(deleted.errors || "Deleted");
            else
                toast.success("Deleted" + JSON.stringify(deleted));
        }catch (e) {
            console.log(e);
            toast.error(e.message || "Deleted error");
        }
    }

    saveSetting  = async () =>{
        try {
            let settingObj = JSON.parse(JSON.stringify(this.state.currentJson));
            settingObj.value.content = this.state.currentContent;
            settingObj.value = JSON.stringify(settingObj.value);

            await API.graphql({query: updateSiteSetting, variables: {input: settingObj}, authMode: "AMAZON_COGNITO_USER_POOLS"});
            toast.success("saved");
        }catch (e) {
            console.log(e);
            toast.error(e.message || "Error updating News");
        }
    }

    render(){
        const { classes } = this.props;
        //if not admin show nothing
        if(!this.state.isReady)
            return null;

        return (
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header/>
                    <Typography variant="h6" gutterBottom>
                        Admin Page
                    </Typography>
                    <Divider/>
                    <AppBar position="static" color="default">
                    <Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    >
                    <Tab label="News"  />
                    <Tab label="Settings"  />
                    </Tabs>
                    </AppBar>
                    <TabPanel value={this.state.value} index={0}>
                        <Typography  variant="h4" gutterBottom>
                            სიახლეების მართვა
                        </Typography>
                        <TextField
                            id="post"
                            name="post"
                            select
                            label="News"
                            fullWidth
                            onChange={this.handleObjectChange}
                            value={this.state.currentObject}
                            variant="outlined"
                        >
                            {this.state.allObjects.map(post => (
                                <MenuItem key={post.id} value={post}>
                                    {post.title}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Editor
							// ADDED BY ACHIKO
							apiKey="jfq045bkvy3xsdlzltxcde8xkmm4ssrxcmjhg87bka7088c6"
                            value={this.state.currentContent}
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image media charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify |bullist numlist outdent indent | removeformat | link | image | media | table | preview | help'
                            }}
                            onEditorChange={this.handleEditorChange}
                        />
                    {<JSONInput
                        placeholder={this.state.currentJson} // data to display
                        theme="light_mitsuketa_tribute"
                        onChange={this.jsonChanged}
                        locale={locale}
                        colors={{
                            string: "#DAA520",
                            background : "#112233",
                            background_warning : "#FF3344"
                        }}
                        height="300px"
                        width="100%"
                    />}
                        <div className={classes.root}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.button}
                                onClick={this.saveNews}
                                startIcon={<SaveIcon />}
                                disabled={!this.state.currentJson.id}
                            >
                                შენახვა
                            </Button>
                        <Button variant="contained" color="primary" onClick={this.createNews} disabled={!!this.state.currentJson.id || !this.state.currentJson.blogID || !this.state.currentContent}>
                            შექმნა
                        </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.deleteNews}
                                startIcon={<DeleteIcon />}
                                disabled={!this.state.currentJson.id}
                            >
                                წაშლა
                            </Button>
                        </div>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={1}>
                        <Typography  variant="h4" gutterBottom>
                            გვერდები
                        </Typography>
                        <TextField
                            id="setting"
                            name="setting"
                            select
                            label="Setting"
                            fullWidth
                            onChange={this.handleObjectChange}
                            value={this.state.currentObject}
                            variant="outlined"
                        >
                            {this.state.allObjects.map(setting => (
                                <MenuItem key={setting.id} value={setting}>
                                    {setting.id}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Editor
                            value={this.state.currentContent}
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image media charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link | image | media |  table | preview | help'
                            }}
                            onEditorChange={this.handleEditorChange}
                        />
                        {<JSONInput
                            placeholder={this.state.currentJson} // data to display
                            theme="light_mitsuketa_tribute"
                            onChange={this.jsonChanged}
                            locale={locale}
                            colors={{
                                string: "#DAA520",
                                background : "#112233",
                                background_warning : "#FF3344"
                            }}
                            height="300px"
                            width="100%"
                        />}
                        <div className={classes.root}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.button}
                                onClick={this.saveSetting}
                                startIcon={<SaveIcon />}
                                disabled={!this.state.currentJson.id}
                            >
                                შენახვა
                            </Button>
                        </div>
                    </TabPanel>
                </Container>
                <Footer/>
            </React.Fragment>
        );
    }
}
AdminPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminPage);
