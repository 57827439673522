import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Config from "../../AppConfig"

// import query definition
import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography";



class ContactPage extends React.Component {

    componentDidMount () {
        const script = document.createElement("script");

        script.src = "https://apps.elfsight.com/p/platform.js";
        script.defer = true;

        document.body.appendChild(script);
    }


    render(){

        return (
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header  active="/contact"/>
                    <Typography variant="h6" gutterBottom align="center">
                        {Config.contact.title}
                    </Typography>
                    <Divider/>
                    <main>
                    <Typography dangerouslySetInnerHTML={{__html:  Config.contact.description}}></Typography>
                        <div className={Config.contact.id}></div>
                    </main>
                </Container>
                <Footer/>
            </React.Fragment>
        );
    }
}

export default ContactPage;