/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const reservedCreateAccount = /* GraphQL */ `
  mutation ReservedCreateAccount(
    $id: ID!
    $isConfirmed: Boolean!
    $username: ID!
    $email: AWSEmail!
    $code: Int!
    $schoolID: ID!
    $grade: Int
    $firstName: String
    $lastName: String
    $birthDate: AWSDate
    $phone: AWSPhone
  ) {
    reservedCreateAccount(
      id: $id
      isConfirmed: $isConfirmed
      username: $username
      email: $email
      code: $code
      schoolID: $schoolID
      grade: $grade
      firstName: $firstName
      lastName: $lastName
      birthDate: $birthDate
      phone: $phone
    ) {
      id
      createdAt
      updatedAt
      schoolID
      username
      email
      grade
      firstName
      lastName
      birthDate
      phone
      isConfirmed
      paid
      diplomas
      school {
        id
        createdAt
        updatedAt
        cityID
        name
        fullName
        address
        phone
        email
        type
        city {
          id
          createdAt
          updatedAt
          regionID
          name
          region {
            id
            createdAt
            updatedAt
            name
          }
          schools {
            nextToken
          }
        }
      }
      tests {
        items {
          id
          accountID
          updatedAt
          createdAt
          testReference
          startedAt
          currentTime
        }
        nextToken
      }
      testResults {
        items {
          id
          createdAt
          updatedAt
          accountID
          testReference
          exercises {
            name
            score
          }
          total
        }
        nextToken
      }
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $schoolID: ID!
    $email: AWSEmail
    $grade: Int
    $firstName: String
    $lastName: String
    $birthDate: AWSDate
    $phone: AWSPhone
  ) {
    createAccount(
      schoolID: $schoolID
      email: $email
      grade: $grade
      firstName: $firstName
      lastName: $lastName
      birthDate: $birthDate
      phone: $phone
    )
  }
`;
export const verifyAccount = /* GraphQL */ `
  mutation VerifyAccount($code: Int!) {
    verifyAccount(code: $code) {
      id
      createdAt
      updatedAt
      schoolID
      username
      email
      grade
      firstName
      lastName
      birthDate
      phone
      isConfirmed
      paid
      diplomas
      school {
        id
        createdAt
        updatedAt
        cityID
        name
        fullName
        address
        phone
        email
        type
        city {
          id
          createdAt
          updatedAt
          regionID
          name
          region {
            id
            createdAt
            updatedAt
            name
          }
          schools {
            nextToken
          }
        }
      }
      tests {
        items {
          id
          accountID
          updatedAt
          createdAt
          testReference
          startedAt
          currentTime
        }
        nextToken
      }
      testResults {
        items {
          id
          createdAt
          updatedAt
          accountID
          testReference
          exercises {
            name
            score
          }
          total
        }
        nextToken
      }
    }
  }
`;
export const updateAccountSettings = /* GraphQL */ `
  mutation UpdateAccountSettings(
    $email: AWSEmail
    $schoolID: ID
    $grade: Int
    $firstName: String
    $lastName: String
    $birthDate: AWSDate
    $phone: AWSPhone
  ) {
    updateAccountSettings(
      email: $email
      schoolID: $schoolID
      grade: $grade
      firstName: $firstName
      lastName: $lastName
      birthDate: $birthDate
      phone: $phone
    ) {
      id
      createdAt
      updatedAt
      schoolID
      username
      email
      grade
      firstName
      lastName
      birthDate
      phone
      isConfirmed
      paid
      diplomas
      school {
        id
        createdAt
        updatedAt
        cityID
        name
        fullName
        address
        phone
        email
        type
        city {
          id
          createdAt
          updatedAt
          regionID
          name
          region {
            id
            createdAt
            updatedAt
            name
          }
          schools {
            nextToken
          }
        }
      }
      tests {
        items {
          id
          accountID
          updatedAt
          createdAt
          testReference
          startedAt
          currentTime
        }
        nextToken
      }
      testResults {
        items {
          id
          createdAt
          updatedAt
          accountID
          testReference
          exercises {
            name
            score
          }
          total
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput) {
    updateUser(input: $input) {
      id
      createdAt
      updatedAt
      schoolID
      username
      email
      grade
      firstName
      lastName
      birthDate
      phone
      isConfirmed
      paid
      diplomas
      school {
        id
        createdAt
        updatedAt
        cityID
        name
        fullName
        address
        phone
        email
        type
        city {
          id
          createdAt
          updatedAt
          regionID
          name
          region {
            id
            createdAt
            updatedAt
            name
          }
          schools {
            nextToken
          }
        }
      }
      tests {
        items {
          id
          accountID
          updatedAt
          createdAt
          testReference
          startedAt
          currentTime
        }
        nextToken
      }
      testResults {
        items {
          id
          createdAt
          updatedAt
          accountID
          testReference
          exercises {
            name
            score
          }
          total
        }
        nextToken
      }
    }
  }
`;
export const createTest = /* GraphQL */ `
  mutation CreateTest($input: CreateTestInput!) {
    createTest(input: $input) {
      id
      accountID
      updatedAt
      createdAt
      testReference
      startedAt
      currentTime
    }
  }
`;
export const createSiteSetting = /* GraphQL */ `
  mutation CreateSiteSetting(
    $input: CreateSiteSettingInput!
    $condition: ModelSiteSettingConditionInput
  ) {
    createSiteSetting(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      value
    }
  }
`;
export const updateSiteSetting = /* GraphQL */ `
  mutation UpdateSiteSetting(
    $input: UpdateSiteSettingInput!
    $condition: ModelSiteSettingConditionInput
  ) {
    updateSiteSetting(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      value
    }
  }
`;
export const deleteSiteSetting = /* GraphQL */ `
  mutation DeleteSiteSetting(
    $input: DeleteSiteSettingInput!
    $condition: ModelSiteSettingConditionInput
  ) {
    deleteSiteSetting(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      value
    }
  }
`;
export const createBlog = /* GraphQL */ `
  mutation CreateBlog(
    $input: CreateBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    createBlog(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      posts {
        items {
          id
          blogID
          createdAt
          updatedAt
          title
          miniContent
          content
          image
          thumbnail
          videoUrl
          blog {
            id
            createdAt
            updatedAt
            name
          }
        }
        nextToken
      }
    }
  }
`;
export const updateBlog = /* GraphQL */ `
  mutation UpdateBlog(
    $input: UpdateBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    updateBlog(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      posts {
        items {
          id
          blogID
          createdAt
          updatedAt
          title
          miniContent
          content
          image
          thumbnail
          videoUrl
          blog {
            id
            createdAt
            updatedAt
            name
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteBlog = /* GraphQL */ `
  mutation DeleteBlog(
    $input: DeleteBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    deleteBlog(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      posts {
        items {
          id
          blogID
          createdAt
          updatedAt
          title
          miniContent
          content
          image
          thumbnail
          videoUrl
          blog {
            id
            createdAt
            updatedAt
            name
          }
        }
        nextToken
      }
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      blogID
      createdAt
      updatedAt
      title
      miniContent
      content
      image
      thumbnail
      videoUrl
      blog {
        id
        createdAt
        updatedAt
        name
        posts {
          items {
            id
            blogID
            createdAt
            updatedAt
            title
            miniContent
            content
            image
            thumbnail
            videoUrl
          }
          nextToken
        }
      }
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      blogID
      createdAt
      updatedAt
      title
      miniContent
      content
      image
      thumbnail
      videoUrl
      blog {
        id
        createdAt
        updatedAt
        name
        posts {
          items {
            id
            blogID
            createdAt
            updatedAt
            title
            miniContent
            content
            image
            thumbnail
            videoUrl
          }
          nextToken
        }
      }
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      blogID
      createdAt
      updatedAt
      title
      miniContent
      content
      image
      thumbnail
      videoUrl
      blog {
        id
        createdAt
        updatedAt
        name
        posts {
          items {
            id
            blogID
            createdAt
            updatedAt
            title
            miniContent
            content
            image
            thumbnail
            videoUrl
          }
          nextToken
        }
      }
    }
  }
`;
export const createRegion = /* GraphQL */ `
  mutation CreateRegion(
    $input: CreateRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    createRegion(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      cities {
        items {
          id
          createdAt
          updatedAt
          regionID
          name
          region {
            id
            createdAt
            updatedAt
            name
          }
          schools {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateRegion = /* GraphQL */ `
  mutation UpdateRegion(
    $input: UpdateRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    updateRegion(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      cities {
        items {
          id
          createdAt
          updatedAt
          regionID
          name
          region {
            id
            createdAt
            updatedAt
            name
          }
          schools {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteRegion = /* GraphQL */ `
  mutation DeleteRegion(
    $input: DeleteRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    deleteRegion(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      cities {
        items {
          id
          createdAt
          updatedAt
          regionID
          name
          region {
            id
            createdAt
            updatedAt
            name
          }
          schools {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createCity = /* GraphQL */ `
  mutation CreateCity(
    $input: CreateCityInput!
    $condition: ModelCityConditionInput
  ) {
    createCity(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      regionID
      name
      region {
        id
        createdAt
        updatedAt
        name
        cities {
          items {
            id
            createdAt
            updatedAt
            regionID
            name
          }
          nextToken
        }
      }
      schools {
        items {
          id
          createdAt
          updatedAt
          cityID
          name
          fullName
          address
          phone
          email
          type
          city {
            id
            createdAt
            updatedAt
            regionID
            name
          }
        }
        nextToken
      }
    }
  }
`;
export const updateCity = /* GraphQL */ `
  mutation UpdateCity(
    $input: UpdateCityInput!
    $condition: ModelCityConditionInput
  ) {
    updateCity(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      regionID
      name
      region {
        id
        createdAt
        updatedAt
        name
        cities {
          items {
            id
            createdAt
            updatedAt
            regionID
            name
          }
          nextToken
        }
      }
      schools {
        items {
          id
          createdAt
          updatedAt
          cityID
          name
          fullName
          address
          phone
          email
          type
          city {
            id
            createdAt
            updatedAt
            regionID
            name
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteCity = /* GraphQL */ `
  mutation DeleteCity(
    $input: DeleteCityInput!
    $condition: ModelCityConditionInput
  ) {
    deleteCity(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      regionID
      name
      region {
        id
        createdAt
        updatedAt
        name
        cities {
          items {
            id
            createdAt
            updatedAt
            regionID
            name
          }
          nextToken
        }
      }
      schools {
        items {
          id
          createdAt
          updatedAt
          cityID
          name
          fullName
          address
          phone
          email
          type
          city {
            id
            createdAt
            updatedAt
            regionID
            name
          }
        }
        nextToken
      }
    }
  }
`;
export const createSchool = /* GraphQL */ `
  mutation CreateSchool(
    $input: CreateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    createSchool(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      cityID
      name
      fullName
      address
      phone
      email
      type
      city {
        id
        createdAt
        updatedAt
        regionID
        name
        region {
          id
          createdAt
          updatedAt
          name
          cities {
            nextToken
          }
        }
        schools {
          items {
            id
            createdAt
            updatedAt
            cityID
            name
            fullName
            address
            phone
            email
            type
          }
          nextToken
        }
      }
    }
  }
`;
export const updateSchool = /* GraphQL */ `
  mutation UpdateSchool(
    $input: UpdateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    updateSchool(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      cityID
      name
      fullName
      address
      phone
      email
      type
      city {
        id
        createdAt
        updatedAt
        regionID
        name
        region {
          id
          createdAt
          updatedAt
          name
          cities {
            nextToken
          }
        }
        schools {
          items {
            id
            createdAt
            updatedAt
            cityID
            name
            fullName
            address
            phone
            email
            type
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteSchool = /* GraphQL */ `
  mutation DeleteSchool(
    $input: DeleteSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    deleteSchool(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      cityID
      name
      fullName
      address
      phone
      email
      type
      city {
        id
        createdAt
        updatedAt
        regionID
        name
        region {
          id
          createdAt
          updatedAt
          name
          cities {
            nextToken
          }
        }
        schools {
          items {
            id
            createdAt
            updatedAt
            cityID
            name
            fullName
            address
            phone
            email
            type
          }
          nextToken
        }
      }
    }
  }
`;
