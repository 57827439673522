import React from "react";
import Container from '@material-ui/core/Container';


import Config from "../../AppConfig"

import { API, Auth } from 'aws-amplify';

import { getAccount, getSiteSetting } from "../../graphql/queries";
import { createTest } from "../../graphql/mutations";


class TestPage extends React.Component {




    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0, isFirstLoading: true, iframeUrl: "", userName: "", email: "", timeRemaining: 1, grade: "", kgb: 0, isReady: false, quiz: {} };

        Auth.currentAuthenticatedUser().then(user => {
            console.log(user); this.setState({ "userName": user.username });
            this.setState({ "email": user.attributes.email });
        }).catch(err => {
            console.log(err);
            window.location.href = "/login"
        });

        this.myRef = React.createRef();
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        //this.refs.iframe.getDOMNode().setAttribute('onload', function(){console.log("hi")});
    }

    async componentDidMount() {
        try {
            const account = await API.graphql({ query: getAccount, variables: {}, authMode: "AMAZON_COGNITO_USER_POOLS" });


            const rawData = await API.graphql({ query: getSiteSetting, variables: { "id": "profile" }, authMode: "API_KEY" });

            let json = JSON.parse(rawData.data.getSiteSetting.value);

            this.setState({ quiz: json.quiz ? json.quiz : {} })


            let userAccount = account.data.getAccount;
            if (userAccount) {
                //this.setState({"firstName":userAccount.firstName});
                this.setState({ "grade": userAccount.grade });
                if (userAccount.tests && userAccount.tests.items && userAccount.tests.items.length) {
                    let currentTest = userAccount.tests.items.filter((t) => t.testReference == window.location.href.split("/").splice(-1)[0]);
                    let isDone = userAccount.tests.items.filter((t) => t.testReference == window.location.href.split("/").splice(-1)[0] + "_done");
                    if (isDone && isDone.length) {
                        alert("დასრულებულია")
                        window.location.href = "/profile";
                    }
                    if (currentTest && currentTest[0]) {
                        let currentTime = new Date(currentTest[0].currentTime).getTime();
                        let startTime = new Date(currentTest[0].startedAt).getTime();
                        let timeElapsed = Math.floor((currentTime - startTime) / 1000)
                        let quizDuration = parseInt(json.quiz.duration);
                        this.setState({ "kgb": json.quiz.kgb || 0 });

                        let timeRemaining = quizDuration - timeElapsed;
                        if (timeRemaining > 0) {
                            this.setState({ "timeRemaining": timeRemaining });
                            this.setState({ "isReady": true });
                        } else {
                            alert("დასრულებულია")
                            window.location.href = "/profile";
                        }
                    } else {
                        this.setState({ "isReady": false });
                    }
                }
                if (!userAccount.isConfirmed) {
                    window.location.href = "/edit-profile"
                }
                this.setState({
                    iframeUrl: "../tests/" + this.state.grade.toString() + ".htm?studentId=" + this.state.userName.toString() + "&email=" + this.state.email + "&check=" + this.state.kgb + "&timeRemaining=" + this.state.timeRemaining
                });
            } else {
                //TODO remove this
                window.location.href = "/edit-profile";
            }
        } catch (e) {
            console.log("Error GetAccount: ", e)
        }



        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        //if(this.state.isFirstLoading){
        this.setState({ width: window.innerWidth, height: window.innerHeight });
        //}
    }

    shuffleArray(array) {
        var currentIndex = array.length,
            temporaryValue,
            randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }


    iframeLoaded = async () => {
        if (!this.state.isFirstLoading) {
            //this.myRef.current.style.height = 10;
            this.setState({ width: 0, height: 0 });

            try {
                let test = await API.graphql({ query: createTest, variables: { input: { testReference: this.state.quiz.id + "_done" } }, authMode: "AMAZON_COGNITO_USER_POOLS" });
                console.log('Success confirmation');
                window.location.href = "/profile";
            } catch (e) {
                //document.location.href="/quiz/" + json.quiz.id;
                console.log(e);
            }

        }





        this.setState({ isFirstLoading: false })
    };






    render() {

        return (
            <>
            {(this.state.iframeUrl.length > 0 && this.state.isReady) &&
            <iframe ref= { this.myRef } id = "iframe" src = { this.state.iframeUrl } onLoad = { this.iframeLoaded } width = "100%" height = { this.state.height }  allow = "microphone; camera" > </iframe>
    }
          </>
        );
    }
}

export default TestPage;