import React from "react";
import UserProfile from "../profile/UserProfile"
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Header from '../common/Header';
import Footer from '../common/Footer';



class ProfilePage extends React.Component {


    render(){
        return (
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header active="/profile"/>
                    <main>
                        <UserProfile/>
                    </main>
                </Container>
                <Footer/>
            </React.Fragment>
        );
    }
}

export default ProfilePage;