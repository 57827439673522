export const listCitiesAndSchools = `query ListCities {
    listCitys(limit: 10000) {
        items {
            id
            name
            schools(limit: 1000, sortDirection: ASC) {
                items {
                    id
                    name
                }
            }
        }
    }
}
`;

export const getBlogPostsPreview = `query GetBlog($id: ID!) {
    getBlog(id: $id) {
      id
      name
      posts(limit: 3, sortDirection: DESC) {
        items {
          id
          blogID
          createdAt
          updatedAt
          title
          miniContent
          content
          image
          thumbnail
          videoUrl
        }
        nextToken
      }
    }
  }
  `;