/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccount = /* GraphQL */ `
  query GetAccount {
    getAccount {
      id
      createdAt
      updatedAt
      schoolID
      username
      email
      grade
      firstName
      lastName
      birthDate
      phone
      isConfirmed
      paid
      diplomas
      school {
        id
        createdAt
        updatedAt
        cityID
        name
        fullName
        address
        phone
        email
        type
        city {
          id
          createdAt
          updatedAt
          regionID
          name
          region {
            id
            createdAt
            updatedAt
            name
          }
          schools {
            nextToken
          }
        }
      }
      tests {
        items {
          id
          accountID
          updatedAt
          createdAt
          testReference
          startedAt
          currentTime
        }
        nextToken
      }
      testResults {
        items {
          id
          createdAt
          updatedAt
          accountID
          testReference
          exercises {
            name
            score
          }
          total
        }
        nextToken
      }
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        schoolID
        username
        email
        grade
        firstName
        lastName
        birthDate
        phone
        isConfirmed
        paid
        diplomas
        school {
          id
          createdAt
          updatedAt
          cityID
          name
          fullName
          address
          phone
          email
          type
          city {
            id
            createdAt
            updatedAt
            regionID
            name
          }
        }
        tests {
          items {
            id
            accountID
            updatedAt
            createdAt
            testReference
            startedAt
            currentTime
          }
          nextToken
        }
        testResults {
          items {
            id
            createdAt
            updatedAt
            accountID
            testReference
            total
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSiteSetting = /* GraphQL */ `
  query GetSiteSetting($id: ID!) {
    getSiteSetting(id: $id) {
      id
      createdAt
      updatedAt
      value
    }
  }
`;
export const listSiteSettings = /* GraphQL */ `
  query ListSiteSettings(
    $filter: ModelSiteSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        value
      }
      nextToken
    }
  }
`;
export const getBlog = /* GraphQL */ `
  query GetBlog($id: ID!) {
    getBlog(id: $id) {
      id
      createdAt
      updatedAt
      name
      posts {
        items {
          id
          blogID
          createdAt
          updatedAt
          title
          miniContent
          content
          image
          thumbnail
          videoUrl
          blog {
            id
            createdAt
            updatedAt
            name
          }
        }
        nextToken
      }
    }
  }
`;
export const listBlogs = /* GraphQL */ `
  query ListBlogs(
    $filter: ModelBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        name
        posts {
          items {
            id
            blogID
            createdAt
            updatedAt
            title
            miniContent
            content
            image
            thumbnail
            videoUrl
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      blogID
      createdAt
      updatedAt
      title
      miniContent
      content
      image
      thumbnail
      videoUrl
      blog {
        id
        createdAt
        updatedAt
        name
        posts {
          items {
            id
            blogID
            createdAt
            updatedAt
            title
            miniContent
            content
            image
            thumbnail
            videoUrl
          }
          nextToken
        }
      }
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        blogID
        createdAt
        updatedAt
        title
        miniContent
        content
        image
        thumbnail
        videoUrl
        blog {
          id
          createdAt
          updatedAt
          name
          posts {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        accountID
        date
      }
      nextToken
    }
  }
`;
export const getRegion = /* GraphQL */ `
  query GetRegion($id: ID!) {
    getRegion(id: $id) {
      id
      createdAt
      updatedAt
      name
      cities {
        items {
          id
          createdAt
          updatedAt
          regionID
          name
          region {
            id
            createdAt
            updatedAt
            name
          }
          schools {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listRegions = /* GraphQL */ `
  query ListRegions(
    $filter: ModelRegionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        name
        cities {
          items {
            id
            createdAt
            updatedAt
            regionID
            name
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCity = /* GraphQL */ `
  query GetCity($id: ID!) {
    getCity(id: $id) {
      id
      createdAt
      updatedAt
      regionID
      name
      region {
        id
        createdAt
        updatedAt
        name
        cities {
          items {
            id
            createdAt
            updatedAt
            regionID
            name
          }
          nextToken
        }
      }
      schools {
        items {
          id
          createdAt
          updatedAt
          cityID
          name
          fullName
          address
          phone
          email
          type
          city {
            id
            createdAt
            updatedAt
            regionID
            name
          }
        }
        nextToken
      }
    }
  }
`;
export const listCitys = /* GraphQL */ `
  query ListCitys(
    $filter: ModelCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        regionID
        name
        region {
          id
          createdAt
          updatedAt
          name
          cities {
            nextToken
          }
        }
        schools {
          items {
            id
            createdAt
            updatedAt
            cityID
            name
            fullName
            address
            phone
            email
            type
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSchool = /* GraphQL */ `
  query GetSchool($id: ID!) {
    getSchool(id: $id) {
      id
      createdAt
      updatedAt
      cityID
      name
      fullName
      address
      phone
      email
      type
      city {
        id
        createdAt
        updatedAt
        regionID
        name
        region {
          id
          createdAt
          updatedAt
          name
          cities {
            nextToken
          }
        }
        schools {
          items {
            id
            createdAt
            updatedAt
            cityID
            name
            fullName
            address
            phone
            email
            type
          }
          nextToken
        }
      }
    }
  }
`;
export const listSchools = /* GraphQL */ `
  query ListSchools(
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        cityID
        name
        fullName
        address
        phone
        email
        type
        city {
          id
          createdAt
          updatedAt
          regionID
          name
          region {
            id
            createdAt
            updatedAt
            name
          }
          schools {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
