import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Config from "../../AppConfig";
import Grid from "@material-ui/core/Grid";

function Copyright(title,description) {
  return (
    <Typography variant="body2" color="inherit" align="center">
      {Config.footer.copyrightLabel + ' © '}
      <Link color="inherit" href="">
        {Config.footer.copyrightText}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: "#333",//theme.palette.background.paper,
    // background: "url(assets/footer-bg.jpg)",
    borderTop : "4px solid #FFC107",
    // marginTop: theme.spacing(8),
    padding: theme.spacing(2, 0),
    color : "#fff",
    fontFamily : "BPG Nino Mtavruli, sans-serif"
  },
  root: {
    flexGrow: 1
  },
  socialItem: {
    textDecoration : "none"
  },
  socialLabel:{
    marginTop: "-6px"
  }
}));

export default function Footer(props) {
  const classes = useStyles();
  let { description, title } = props;
  if(!description)
    description = Config.footer.description;
  if(!title)
    title = Config.footer.title;

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center" direction={ true ? 'row' : 'column'}>
              {Config.footer.social.map((network,index) => (
                  <Grid key={index}  item xs={12} md={3}>
                    <Link display="block" color="inherit" target={index ? "_self" :"_blank"} variant="body1" href={network.url} className={classes.socialItem}>
                      <Grid container direction="row" spacing={0} style={{minWidth: "240px"}} alignItems="center" className="center-footer">
                        <Grid item>
                          <network.icon />
                        </Grid>
                        <Grid item className={classes.socialLabel}>{network.name}</Grid>
                      </Grid>
                    </Link>
                  </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Copyright title={title} description={description} />
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
