import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Config from "../../AppConfig"

// import query definition
import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography";

import Faq from 'react-faq-component';
import { API } from 'aws-amplify';
import {getSiteSetting} from "../../graphql/queries";





class FAQPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            content : "",
            title : "",
            data : {}
        }
    }

    async componentDidMount() {
        //console.log(Storage.get('IMG_1234.JPG',{ level: 'public' }));
        try {
            const rawData = await API.graphql({query:getSiteSetting,variables:{"id":"faq"},authMode:"API_KEY"});

            let json = JSON.parse(rawData.data.getSiteSetting.value);
            this.setState({data: json});
            //this.setState({title: json.title});

        } catch (err) {
            console.log('error fetching settings...', err)
        }
    }



    render(){
        const styles = {
             bgColor: '#fafafa',
            titleTextColor: 'blue',
            rowTitleColor: 'blue',
            // rowContentColor: 'grey'
        }
        return (
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header/>
                    <Typography variant="h6" gutterBottom align="center">
                        {this.state.title || Config.FAQ.title}
                    </Typography>
                    <Divider/>
                    <main>
                        <Faq data={this.state.data} styles={styles}/>
                    </main>
                </Container>
                <Footer/>
            </React.Fragment>
        );
    }
}

export default FAQPage;
