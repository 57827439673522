import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Header from '../common/Header';
import Footer from '../common/Footer';

import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography";
import TablePagination from '@material-ui/core/TablePagination';
import Config from "../../AppConfig";

// imports from Amplify library
import { API } from 'aws-amplify';

// import query definition
import {getBlog as GetBlog} from '../../graphql/queries'
import Grid from "@material-ui/core/Grid";
import FeaturedPost from "../blog/FeaturedPost";
import List from "react-content-loader";



class DynamicPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = { allPosts: [], posts : [], page : 0, rowsPerPage : Config.posts.paginationOptions.length ? Config.posts.paginationOptions[0]: 12 }
    }
    // execute the query in componentDidMount
    async componentDidMount() {
        //console.log(Storage.get('IMG_1234.JPG',{ level: 'public' }));
        try {
            const blogId = this.props.id;
            const blogs = await API.graphql({query:GetBlog,variables:{"id":blogId},authMode:"API_KEY"});

            //console.log('allPosts:', blogs.data.getBlog.posts.items)
            var posts = blogs.data.getBlog.posts.items.map(function(el){
                el.description = el.content;
                el.date = el.updatedAt.toString();
                el.image = el.image ? el.image : "https://kslides.s3.eu-central-1.amazonaws.com/K-thumb1_web.jpg";
                el.imageText = "Image Text";
                return el;
            });
            this.setState({posts: posts.slice(0,this.state.rowsPerPage)});
            this.setState({allPosts: posts});
            // this.setState({
            //   posts: blogs.data.listBlogs.items
            // })
        } catch (err) {
            console.log('error fetching posts...', err)
        }
    }

    handleChangePage = (event,newPage) => {
        this.setState({page: newPage});
        this.setState({posts: this.state.allPosts.slice().splice(this.state.rowsPerPage * parseInt(newPage),this.state.rowsPerPage)});
    };
    
    handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage :parseInt(event.target.value, 10)});
    this.setState({'page': 0});
    this.setState({posts: this.state.allPosts.slice().splice(0,parseInt(event.target.value, 10))});
    };

    render(){
        const { title,url } = this.props;
        const { posts, allPosts } = this.state;
        return (
            <React.Fragment>
            <CssBaseline />
            <Container maxWidth="lg">
                <Header  active={url}/>
                <Typography variant="h6" gutterBottom align="center">
                    {title}
                </Typography>
                <Divider/>
                <main>
                    {posts && posts.length ? (
                        <Grid container spacing={4}>
                            {posts.map((post,index) => (
                                <FeaturedPost key={index} post={post} hideText={true} />
                            ))}
                        </Grid>
                    ) : (
                        <List/>
                    )}
                </main>
            </Container>
            <TablePagination
          rowsPerPageOptions={Config.posts.paginationOptions}
          component="div"
          count={allPosts.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          labelDisplayedRows={Config.posts.paginationLabel}
          labelRowsPerPage={Config.posts.labelRowsPerPage}
          backIconButtonText={Config.posts.backIconButtonText}
          nextIconButtonText={Config.posts.nextIconButtonText}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
            <Footer/>
        </React.Fragment>
        );
    }
}

export default DynamicPage;
