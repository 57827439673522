import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Config from "../../AppConfig"

const useStyles = makeStyles({
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    height: 160
  },
  cardDesc: {
    maxHeight: '400px',
    overflow : 'hidden',
    textAlign: 'justify'
  }
});

export default function FeaturedPost(props) {
  const classes = useStyles();
  const { post, hideText } = props;

  return (
    <Grid item xs={12} md={4}>
      <CardActionArea component="a" href={"/posts/"+post.id}>
        <Card className={classes.card}>
          <div className={classes.cardDetails}>
            <CardContent>
              <Typography component="h2" variant="h5">
                {post.title}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {Config.formatDate(post.date)}
              </Typography>
              <CardMedia className={classes.cardMedia} image={post.image} title={post.imageTitle} />
              {!hideText ?
                  <Typography className={classes.cardDesc}  variant="subtitle1" paragraph dangerouslySetInnerHTML={{ __html:post.miniContent}}></Typography> :
                  <></>
              }
              <Typography variant="subtitle1" color="primary" align="center">
                {Config.posts.readMore}
              </Typography>
            </CardContent>
          </div>
        </Card>
      </CardActionArea>
    </Grid>
  );
}

FeaturedPost.propTypes = {
  post: PropTypes.object,
  hideText : PropTypes.bool
};
