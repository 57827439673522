import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Post from "../blog/Post";
// imports from Amplify library
import { API } from 'aws-amplify';

// import query definition
import {getPost as GetPost} from '../../graphql/queries'



class PostPage extends React.Component {


    constructor(props) {
        super(props);
        this.state ={
            post:null,
            postId: this.props.match.params.id
        };
    }

    // execute the query in componentDidMount
    async componentDidMount() {
        try {
            const post =  await API.graphql({query:GetPost,variables:{"id":this.state.postId},authMode:"API_KEY"});
            console.log('post:', post.data);
            if(post.data && post.data.getPost)
                this.setState({
                     post: post.data.getPost
                });
        } catch (err) {
            console.log('error fetching Post...', err)
        }
    }

    render(){
        const { post } = this.state;

        return (
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header/>
                    <main>
                        <Post post={post}/>
                    </main>
                </Container>
                <Footer/>
            </React.Fragment>
        );
    }
}

export default PostPage;
