import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles';

import Toolbar from '@material-ui/core/Toolbar';
//import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';
//import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
//import Hidden from '@material-ui/core/Hidden';

import Config from "../../AppConfig";
import { Auth } from 'aws-amplify';

const styles = (theme => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding : '0'
  },
  toolbarLogo: {
    width: '80px'
  },
  toolbarTitle: {
    flex: 1,
    fontFamily: 'ALK Katerina,sans-serif',
    fontSize: '1.5625em',
    marginLeft: '10px'
  },
  toolbarUser:{
    marginRight: '8px'
  },
  space :{
    marginLeft: "auto"
  },
  toolbarSecondary: {
    //justifyContent: 'flex-end',
    //overflowX: 'auto',
    textAlign: "center",
  },
  toolbarLink: {
    padding: theme.spacing(1) * 3,
    //flexShrink: 0,
    fontFamily: 'ALK Katerina,sans-serif',
    fontSize: '1.2em',
    transition: 'all 500ms',
    '&:hover': {
      background: '#ffc107a8',
      textDecoration: 'none',
      color: '#fff'
    },
  },
  toolbarLinkMobile: {
    padding: theme.spacing(1)/2 ,
    //flexShrink: 0,
    fontFamily: 'ALK Katerina,sans-serif',
    fontSize: '1.1em',
    transition: 'all 500ms',
    '&:hover': {
      background: '#ffc107a8',
      textDecoration: 'none',
      color: '#fff'
    },
  }
}));


class Header extends React.Component {



  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  burgerClick = () =>{
    console.log('daksjldka');
    let {burgerClass, navClass} = {...this.state}
    if(burgerClass === 'burger'){
      burgerClass = 'burger clickburger';
      navClass = 'navigation open';
    }
    else{
      burgerClass = 'burger';
      navClass = 'navigation';

    }
    this.setState({burgerClass, navClass});
  }

  constructor(props) {
    super(props);
    this.state = { title: Config.main.title, userName: "", greeting: Config.main.greeting,  width: 0, height: 0, isAdmin : false, burgerClass : "burger", navClass : 'navigation'}
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    Auth.currentAuthenticatedUser().then(user=>{
      /* console.log( user ); */this.setState({"userName":user.username})
      if(user.signInUserSession.accessToken.payload["cognito:groups"] && user.signInUserSession.accessToken.payload["cognito:groups"].indexOf("Admins")!== -1){
        //user is Admin
        this.setState({isAdmin: true});
      }

    }).catch(err => console.log(err))
    this.logout = this.logout.bind(this);
  }

  logout() {
    Auth.signOut().then((user)=>{this.setState({"userName":""})});
  }


  render(){
    const { classes/*, history */,active } = this.props;
    return (
        <React.Fragment>
          <Toolbar className={classes.toolbar}>
              <Grid container  alignItems="center" direction={ this.state.width > 820 ? 'row' : 'column'}>
                <div className="header"> 
                  <div className="logo">
                <Grid item>
                <Link href="/">
                <img src="/logo_full.png" className={classes.toolbarLogo} alt="logo"/>
              </Link>
              </Grid>
              <Grid>
                <Typography
                    component="h2"
                    variant="h5"
                    color="inherit"
                    align="left"
                    noWrap
                    className={classes.toolbarTitle}
                >
                  {this.state.title}
                </Typography>
                </Grid>
                <Grid className={classes.space}>
                <Typography> </Typography>
                </Grid>
                  {this.state.isAdmin && <Grid item={true}  key={"admin"}><Link
                      color="inherit"
                      noWrap
                      variant="body2"
                      href={"/admin"}
                      style={{ backgroundColor: "#f75b17" }}
                      className={this.state.width > 820 ?  classes.toolbarLink : classes.toolbarLinkMobile}
                  >
                    {"ADMIN"}
                  </Link></Grid>}
                  </div>
                  <nav className={this.state.navClass}>
              {Config.sections.items.map(section => (
                section.title ?
                  (<Grid item={true}  key={section.url}><Link
                      color="inherit"
                      noWrap
                      variant="body2"
                      href={section.url}
                      style={{ backgroundColor: section.url===active ? "#FFC107" : "auto" }}
                      className={this.state.width > 820 ?  classes.toolbarLink : classes.toolbarLinkMobile}
                  >
                    {section.title}
                  </Link></Grid>) : Config.dynamic.items.map(dynSection =>(
                    <Grid item
                    key={dynSection.url}
                  ><Link
                    color="inherit"
                    noWrap
                    variant="body2"
                    href={dynSection.url}
                    style={{ backgroundColor: dynSection.url===active ? "#FFC107" : "auto" }}
                    className={this.state.width > 820 ?  classes.toolbarLink : classes.toolbarLinkMobile}
                >
                  {dynSection.title}
                </Link>
                </Grid>
                  ))
              ))
              }
              </nav>
                  <div className={this.state.burgerClass} onClick={this.burgerClick}>
                      <span></span>
                      <span></span>
                      <span></span>
                  </div>
                  </div>
            </Grid>
            {/* <Typography
                component="h5"
                variant="body2"
                color="inherit"
                align="right"
                noWrap
                className={classes.toolbarUser}
            >
              {this.state.userName.length ? this.state.greeting + " " + this.state.userName : ""}
            </Typography>
              {this.state.userName.length ? (
                  <Button variant="outlined" size="small" onClick={this.logout}>{Config.main.logoutLabel}</Button>
              ) : (
                <Button variant="outlined" size="small" onClick={(event) => {
                  history.push("/login")
              }}>{Config.main.loginLabel}</Button>
              )} */}
          </Toolbar>
        </React.Fragment>
    );
  }
}
Header.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(withRouter(Header));
