import React from "react";
//import logo from './logo.svg';
import { Switch, Route, Router } from "react-router-dom";
import { toast } from "react-toastify";
import { createBrowserHistory } from "history";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import BackToTop from "react-back-to-top-button";
import ExpandLess from "@material-ui/icons/ExpandLess";
import MainPage from "./components/pages/MainPage";
import ProfilePage from "./components/pages/ProfilePage";
import PostPage from "./components/pages/PostPage";
import AboutPage from "./components/pages/AboutPage";
import OlympiadPage from "./components/pages/OlympiadPage";
import PaymentPage from "./components/pages/PaymentPage";
import ContactPage from "./components/pages/ContactPage";
import FAQPage from "./components/pages/FAQPage";
import AdminPage from "./components/pages/AdminPage";
import QuizPage from "./components/pages/QuizPage";
import TestPage from "./components/pages/TestPage";
import ManageProfilePage from "./components/pages/ManageProfilePage";
import LoginPage from "./components/pages/LoginPage";

import DynamicPage from "./components/pages/DynamicPage";

import ReactGA from "react-ga";
import Config from "./AppConfig";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ResetPassword from "./components/pages/ResetPassword";

import Amplify, { I18n, Hub } from "aws-amplify";
import awsExports from "./aws-exports";
// Amplify.configure(awsExports);

Amplify.configure({
  API: {
    aws_appsync_graphqlEndpoint: awsExports.aws_appsync_graphqlEndpoint,
    aws_appsync_region: awsExports.aws_appsync_region,
    aws_appsync_authenticationType: awsExports.aws_appsync_authenticationType,
    aws_appsync_apiKey: awsExports.aws_appsync_apiKey,
    aws_user_files_s3_bucket: awsExports.aws_user_files_s3_bucket,
    aws_user_files_s3_bucket_region: awsExports.aws_user_files_s3_bucket_region,
  },
  Auth: {
    identityPoolId: awsExports.aws_cognito_identity_pool_id,
    region: awsExports.aws_cognito_region,
    userPoolId: awsExports.aws_user_pools_id,
    userPoolWebClientId: awsExports.aws_user_pools_web_client_id,
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  Storage: {
    aws_user_files_s3_bucket: awsExports.aws_user_files_s3_bucket,
    aws_user_files_s3_bucket_region: awsExports.aws_user_files_s3_bucket_region,
  },
});

toast.configure();

const trackingId = Config.googleAnaliticsIc;
ReactGA.initialize(trackingId);

ReactGA.pageview(window.location.pathname + window.location.search);

const authScreenLabels = {
  ka: Config.lang,
};

I18n.putVocabularies(authScreenLabels);
I18n.setLanguage("ka");

export const history = createBrowserHistory();

//Auth.signIn("ilia","Meudon2019");

// store the form state

// sign the user up
// let state = {
//   username: 'ilia7', email: 'ilia@top.ge', password: 'Meudon2020'
// };
// const { username, email, password } = state;
// Auth.signUp({ username, password, attributes: { email }})
// console.log('user successfully signed up')

//Auth.signOut();

// function check(){
//   Auth.currentSession()
//     .then(data => console.log(data))
//     .catch(err => console.log(err));
// }
// check();

//console.log(Auth.verifyCurrentUserAttribute('email'))
//console.log(Auth.verifyCurrentUserAttributeSubmit('email', "948982"))
class App extends React.Component {
  constructor(props) {
    super(props);
    Hub.listen("auth", (data) => {
      const { payload } = data;
      /*setTimeout(
                function() {
                    this.onAuthEvent(payload);
                }
                    .bind(this),
                1000
            );*/
      this.onAuthEvent(payload);
      console.log(
        "A new auth event has happened: ",
        (data.payload.data ? data.payload.data.username : "unknown") +
          " has " +
          data.payload.event
      );
    });
  }
  onAuthEvent(payload) {
    switch (payload.event) {
      case "signOut":
        //history.push("/");
        break;
      case "signIn":
        //history.push("/edit-profile");
        //workaround error
        //window.location.href = "/edit-profile";
        //window.location.reload();
        break;
      default:
        console.log("Nothing to do: " + payload.event);
    }
  }

  render() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="App">
          <Router history={history}>
            <Switch>
              <Route exact path="/" component={MainPage} />
              <Route exact path="/profile/" component={ProfilePage} />
              <Route
                exact
                path="/(first-login|edit-profile|manage-profile)/"
                component={ManageProfilePage}
              />
              <Route exact path="/login/" component={LoginPage} />
              <Route exact path="/reset-password/" component={ResetPassword} />
              <Route path="/posts/:id" exact component={PostPage} />
              <Route path="/about/" exact component={AboutPage} />
              <Route path="/olympiad/" exact component={OlympiadPage} />
              <Route path="/payment/" exact component={PaymentPage} />
              <Route path="/contact/" exact component={ContactPage} />
              <Route path="/FAQ/" exact component={FAQPage} />
              <Route path="/admin/" exact component={AdminPage} />
              <Route path="/quiz/:id" exact component={TestPage} />
              {Config.dynamic.items.map((dynSection, index) => (
                <Route
                  path={dynSection.url}
                  key={index}
                  exact
                  render={(props) => <DynamicPage {...dynSection} />}
                />
              ))}
            </Switch>
          </Router>
          <BackToTop
            showAt={100}
            speed={1500}
            style={{
              background: "#FFC107",
              width: "50px",
              height: "75px",
              borderRadius: "4px",
              marginBottom: "3px",
              right: "-25px",
            }}
            easing="easeInOutQuint"
          >
            <ExpandLess />
          </BackToTop>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default App;
