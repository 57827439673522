import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { toast } from 'react-toastify';
import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Config from "../../AppConfig"
// imports from Amplify library
import { API, Auth } from 'aws-amplify';
//import { /*listCitys,,listStudents*/, getSettings} from "../../graphql/queries";
import {getAccount, getSiteSetting} from "../../graphql/queries";
import {listCitiesAndSchools} from "../../graphql/customQueries"
import {createAccount, updateAccountSettings} from "../../graphql/mutations";
import { KeyboardDatePicker,  MuiPickersUtilsProvider  } from "@material-ui/pickers";
import kaLocale from "date-fns/locale/ka";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";

const styles = (theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submitWrapper: {
        margin: theme.spacing(3, 0, 2),
        textAlign: "center"
    },
    submit: {
        width : "42%"
    }
}));

const grades = [
    {
        value: '1',
        label: '1',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3',
    },
    {
        value: '4',
        label: '4',
    },
    {
        value: '5',
        label: '5',
    },
    {
        value: '6',
        label: '6',
    },
    {
        value: '7',
        label: '7',
    },
    {
        value: '8',
        label: '8',
    },
    {
        value: '9',
        label: '9',
    },
    {
        value: '10',
        label: '10',
    },
    {
        value: '11',
        label: '11',
    },
    {
        value: '12',
        label: '12',
    }
];



class ManageProfilePage extends React.Component {

    async componentDidMount(){
        console.log(this.state);
        try {
            const olympiad = await API.graphql({query:getSiteSetting,variables:{"id":"olympiad"},authMode:"API_KEY"});

            let json = JSON.parse(olympiad.data.getSiteSetting.value);
            this.setState({content: json.content});

        } catch (err) {
            console.log('error fetching settings...', err)
        }
    }

    inputChange(state,value){
        if(value && state === "userName"){
            if(isNaN(value)){
                alert('შეიყვანეთ მხოლოდ ციფრები');
                value = '';
            }
        }
        if(state === "phone"){
            if(value.indexOf('+995')< 0){
                value = '+995'
            }
            if(isNaN(value)){
                alert('შეიყვანეთ მხოლოდ ციფრები')
                value = value.substr('0', value.length - 1);
            }
            if(value.length > 13){
                value = value.substr('0', 13);
            }
        }
        this.setState({
        [state]: value
        });
    }

    handleCityChange = (event) => {
        this.setState({ city: event.target.value });
        this.setState({ schools: this.state.cities[this.state.cities.map(function(x) {return x.id; }).indexOf(event.target.value)].schools.items.sort((a, b) => a.name > b.name ? 1 : -1) });
    };

    handleSchoolChange = (event) => {
        this.setState({ school: event.target.value });
    };

    openPrivacyPolicy = (e) =>{
        let {privacyOpened, privacyClass} = {...this.state};
        if(!privacyOpened){
            privacyClass = "privacy-policy-holder open";
            privacyOpened = true;
        }
        else{
            if(e.target.className === 'privacy-policy-holder open'){
                privacyClass = "privacy-policy-holder";
                privacyOpened = false;
            }
        }

        this.setState({privacyOpened, privacyClass});
    }

    handleSubmit = async  (event) => {
        event.preventDefault();
        let valid = this.validateInputs();
        console.log(valid);

        console.log(valid);
        if(valid){
            const data = {
                schoolID :this.state.school,
                //cityID :this.state.city,
                email : this.state.email,
                grade : this.state.grade,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                // birthDate : moment(this.state.birthDate).format('YYYY-MM-DD'),
                birthDate : moment(this.state.birthDate).format('YYYY-MM-DD'),
                phone: this.state.phone
            };
            //console.log(data);
            if(this.state.noAccount){
                if (this.state.password.length < 6) {
                    alert('Password length < 6');
                    return;
                }
                if(this.state.userName.length !== 11){
                    alert('Personal Number must be 11 characters in length');
                    return;
                }
                if(!this.state.checkbox){
                    alert('გთხოვთ დაეთანხმეთ წესებსა და პირობებს');
                    return;
                }
                let obj = {
                    username: this.state.userName, email: this.state.email, phone_number: this.state.phone, password: this.state.password
                };
                const {username, email, password, phone_number} = obj;
                this.loader.current.style.display = "flex";
                try {
                    await Auth.signUp({username, password, attributes: {email,phone_number}})
                    console.log('user successfully signed up');
                    this.loader.current.style.display = "flex";
                    // this.submitButton.current.setAttribute("disabled", true);
                    try {
                        this.loader.current.style.display = "flex";
                        await Auth.signIn(username, password);
                        //create account
                        const account = await API.graphql({query:createAccount,variables:data,authMode:"AMAZON_COGNITO_USER_POOLS"});
                        console.log("Created : ",account);
                        toast.success(Config.profile.success);
                        window.location.href = "/profile";
                    }catch (e) {
                        if(e && e.message && e.message.indexOf("already exists")!==-1)
                            toast.error("მომხმარებელი უკვე არსებობს");
                        else
                            toast.error(e.message || "Error Creating account");
                        console.log(e);
                        // this.submitButton.current.setAttribute("disabled", false);
                        this.loader.current.style.display = "none";
                    }
                }catch (e) {
                    this.loader.current.style.display = "none";
                    if(e && e.message && e.message.indexOf("already exists")!==-1)
                        toast.error("მომხმარებელი უკვე არსებობს");
                    console.log(e);
                    // this.submitButton.current.setAttribute("disabled", false);
                }
            }else{
                try {
                    //update account
                    const account = await API.graphql({
                        query: updateAccountSettings,
                        variables: data,
                        authMode: "AMAZON_COGNITO_USER_POOLS"
                    });
                    console.log("Updated : ", account);
                    toast.success(Config.profile.success);
                    window.location.href = "/profile";
                }catch (e) {
                    toast.error(e.message || "Error Updating user info");
                    console.log(e);
                }
            }
        }

    }

    constructor(props) {
        super(props);
        this.submitButton = React.createRef();
        this.loader = React.createRef();
        this.state = {
            city : "",
            cityError: '',
            cities : [],
            school : "",
            schoolError: '',
            schools : [],
            grade : "",
            gradeError: "",
            firstName : "",
            firstNameError: "",
            lastName : "",
            lastNameError: "",
            birthDate : null,
            birthDateError: "",
            email: "",
            emailError: "",
            retryPassword : "",
            retryPasswordError: "",
            phone: "+995",
            phoneError: "",
            userName: "",
            userNameError: "",
            password:"",
            passwordError: "",
            checkbox : false,
            noAccount : false,
            privacyOpened : false,
            privacyClass : 'privacy-policy-holder',
            valid : true,
            content : "",
            // authenticated : false
        }
    }

    async UNSAFE_componentWillMount() {
            try {

          const cts = await API.graphql({query:listCitiesAndSchools,variables:{},authMode:"API_KEY"});
          //console.log(cts);

          //console.log(cts.data.listCitys.items.sort((a, b) => a.name > b.name ? 1 : -1));
          this.setState({cities: cts.data.listCitys.items.sort((a, b) => a.name > b.name ? 1 : -1)});

          Auth.currentAuthenticatedUser().then(user=>{

              if(user.attributes.email)
                this.setState({"email":user.attributes.email});
              if(user.attributes.phone_number)
                this.setState({"phone":user.attributes.phone_number});

          }).catch(err => {console.log(err); this.setState({"noAccount":true});});


          // this.setState({
            //           //   posts: blogs.data.listBlogs.items
            //           // })

          const account = await API.graphql({query:getAccount,variables:{},authMode:"AMAZON_COGNITO_USER_POOLS"});


          let userAccount = account.data.getAccount;

          if(userAccount){
              //console.log(userAccount);
              this.setState({"noAccount":false});

              this.setState({"email":userAccount.email});
              this.setState({"phone":userAccount.phone});
              this.setState({"firstName":userAccount.firstName});
              this.setState({"lastName":userAccount.lastName});
              this.setState({"birthDate":userAccount.birthDate});
              this.setState({"grade":userAccount.grade});
              let cityId= this.state.cities.filter(function(el){return JSON.stringify(el.schools.items).indexOf(userAccount.schoolID)!==-1})[0].id;
              this.setState({"city":cityId});
              this.handleCityChange({target : {value : cityId}})
              this.setState({"school":userAccount.schoolID});

          }else{
              this.setState({"noAccount":true});
          }
        } catch (err) {
          console.log('error fetching cities...', err)
        }
      }

      validateInputs(){
        const {city, school, grade, firstName, lastName, birthDate, email, phone, userName, password, checkbox, retryPassword} = {...this.state};
        let valid = this.state.valid;
        if(city == ''){
            this.setState({cityError:'შეავსეთ ქალაქის ველი'});
            valid = false;
        }
        else{
            this.setState({cityError:''});
            !valid ? valid = false : valid = true;
        }

        if(school == ''){
            this.setState({schoolError: 'შეავსეთ სკოლის ველი'})
            valid = false;
        }
        else{
            this.setState({schoolError: ''});
            !valid ? valid = false : valid = true;
        }

        if(grade == ''){
            this.setState({gradeError: 'შეავსეთ კლასის ველი'})
            valid = false;
        }
        else{
            this.setState({gradeError: ''})
            !valid ? valid = false : valid = true;
        }

        if(firstName == ''){
            this.setState({firstNameError: 'შეავსეთ სახელის ველი'});
            valid = false;
        }else{
            this.setState({firstNameError: ''});
            !valid ? valid = false : valid = true;
        }

        if(lastName == ''){
            this.setState({lastNameError: 'შეავსეთ გვარის ველი'});
            valid = false;
        }
        else{
            this.setState({lastNameError: ''});
            !valid ? valid = false : valid = true;
        }

        if(birthDate == '' || birthDate == null){
            this.setState({birthDateError: 'შეავსეთ დაბადების თარიღის ველი'});
            valid = false;
        }
        else{
            this.setState({birthDateError: ''});
            !valid ? valid = false : valid = true;
        }

        if(email == ''){
            this.setState({emailError : 'შეავსეთ მეილის ველი'});
            valid = false;
        }
        else{
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!(re.test(String(email).toLowerCase()))){
                this.setState({emailError : 'გთხოვთ შეიყვანეთ მეილი სწორი ფორმატით'});
                valid = false;
            }
            else{
                this.setState({emailError : ''});
                !valid ? valid = false : valid = true;
            }
        }

        if(phone == ''){
            this.setState({phoneError: 'შეავსეთ ტელეფონის ველი'});
            valid = false;
        }
        else{
            if(phone[4]!='5' || phone.length != 13 || ["14", "44", "50", "51", "55", "57", "58", "59", "68", "70", "71", "74", "77", "78", "79", "91", "92", "93", "95", "96", "97", "98", "99"].indexOf(phone.substring(5,7))===-1){
                this.setState({phoneError: 'გთხოვთ შეიყვანოთ ნომერი სწორი ფორმატით'});
                valid = false;
            }
            else{
                this.setState({phoneError: ''});
                !valid ? valid = false : valid = true;
            }
        }


        if(this.state.noAccount){
            if(userName == ''){
                this.setState({userNameError: 'შეავსეთ მომხმარებლის სახელის ველი'});
                valid = false;
            }
            else{
                if(userName.length != 11){
                    this.setState({userNameError: 'პირადი ნომრის სიგრძე უნდა იყოს 11 სიმბოლო'});
                    valid = false;
                }
                else{
                    this.setState({userNameError: ''});
                    !valid ? valid = false : valid = true;
                }
            }
            if(password == ''){
                this.setState({passwordError: 'შეავსეთ პაროლის ველი'});
                valid = false;
            }
            else{
                if(password.length < 8){
                    this.setState({passwordError: 'პაროლის ველი უნდა შედგებოდეს მინიმუმ 8 სიმბოლოსგან'});
                    valid = false;
                }
                else{
                    this.setState({passwordError: ''});
                    !valid ? valid = false : valid = true;
                }
            }

            if(password != retryPassword){
                this.setState({retryPasswordError: 'შეყვანილი პაროლები ერთმანეთს არ ემთხვევა'});
                valid = false;
            }
            else{
                this.setState({retryPasswordError: ''});
                !valid ? valid = false : valid = true;

            }
            if(checkbox == ''){
                alert('გთხოვთ დაეთანხმეთ წესებსა და პირობებს');
                valid = false;
            }
        }
        return valid;
      }

      checkSchools = () =>{
          if(!this.state.city){
              let schoolError = this.state.schoolError;
              schoolError = "სკოლის ასარჩევად გთხოვთ აირჩიოთ რაიონი/ქალაქი";
              this.setState({schoolError});
        }
    }

    render(){
        const { classes } = this.props;
        const { noAccount } = this.state;
        const {cityError, schoolError, gradeError, firstNameError, lastNameError, birthDateError, emailError, phoneError, userNameError, passwordError,retryPasswordError} = {...this.state};
        return (
            <React.Fragment>
                <div className="loader-holder" ref = {this.loader}>
                    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
                <div className={this.state.privacyClass} onClick={this.openPrivacyPolicy}>
                    <div className="privacy-policy">
                            თანახმა ვარ და უფლებამოსილებას ვანიჭებ „კენგურუ“-ს გამოიყენოს ოლიმპიადის კონკურსებზე
                            დარეგისტრირების დროს მითითებული ჩემი პერსონალური მონაცემები მარკეტინგული
                            მიზნებისთვის. თანახმა ვარ დაფიქსირებულ ტელეფონის ნომერზე გამომეგზავნოს საინფორმაციო
                            ხასიათის შეტყობინებები.
                        <Typography dangerouslySetInnerHTML={{__html:  this.state.content}}></Typography>
                    </div>
                </div>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header active="/profile"/>
                    <Typography variant="h6" gutterBottom align="center">
                        {noAccount ? Config.profile.createProfile : Config.profile.editProfile}
                    </Typography>
                    <Divider/>
                    <main>
                        <Container component="main" maxWidth="lg">
                            <CssBaseline />
                            <div className={classes.paper}>
                                <form className={classes.form} onSubmit={this.handleSubmit} noValidate="novalidate" >
                                    <Grid container spacing={2}>
                                        {noAccount ? (
                                            <>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        autoComplete="new-password"
                                                        name="userName"
                                                        variant="outlined"
                                                        error = {userNameError.length > 0 ? true : false}
                                                        // required
                                                        fullWidth
                                                        value={this.state.userName}
                                                        onChange={e => this.inputChange("userName",e.target.value)}
                                                        id="userName"
                                                        label="პირადი ნომერი"
                                                    />
                                                    <span className="error">{userNameError}</span>
                                                </Grid>
                                                <Grid item xs={12} sm={6} className="pass-div">
                                                    <div className="password-holder">
                                                        <TextField
                                                            autoComplete="new-password"
                                                            name="password"
                                                            type="password"
                                                            variant="outlined"
                                                            minLength={8}
                                                            error = {passwordError.length > 0 ? true : false}
                                                            // required
                                                            fullWidth
                                                            value={this.state.password}
                                                            onChange={e => this.inputChange("password",e.target.value)}
                                                            id="password"
                                                            label="პაროლი"
                                                        />
                                                        <span className="error">{passwordError}</span>
                                                    </div>
                                                    <div className="password-holder">
                                                        <TextField
                                                            autoComplete="new-password"
                                                            name="retryPassword"
                                                            type="password"
                                                            variant="outlined"
                                                            minLength={8}
                                                            error = {retryPasswordError.length > 0 ? true : false}
                                                            // required
                                                            fullWidth
                                                            value={this.state.retryPassword}
                                                            onChange={e => this.inputChange("retryPassword",e.target.value)}
                                                            id="retryPassword"
                                                            label="გაიმეორეთ პაროლი"
                                                        />
                                                        <span className="error">{retryPasswordError}</span>
                                                    </div>

                                                </Grid>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="firstName"
                                                variant="outlined"
                                                error = {firstNameError.length > 0 ? true : false}
                                                // required
                                                fullWidth
                                                value={this.state.firstName}
                                                onChange={e => this.inputChange("firstName",e.target.value)}
                                                id="firstName"
                                                label="სახელი"
                                            />
                                            <span className="error">{firstNameError}</span>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                error = {lastNameError.length > 0 ? true : false}
                                                // required
                                                fullWidth
                                                value={this.state.lastName}
                                                onChange={e => this.inputChange("lastName",e.target.value)}
                                                id="lastName"
                                                label="გვარი"
                                                name="lastName"
                                            />
                                            <span className="error">{lastNameError}</span>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={kaLocale}>
                                                <KeyboardDatePicker
                                                    autoOk
                                                    value={this.state.birthDate}
                                                    placeholder="31/12/2010"
                                                    onChange={date => this.inputChange("birthDate",date)}
                                                    openTo="year"
                                                    inputVariant="outlined"
                                                    // required
                                                    error = {birthDateError.length > 0 ? true : false}
                                                    fullWidth
                                                    minDate={new Date("1998")}
                                                    maxDate={new Date("2022")}
                                                    helperText=""
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    label="დაბადების თარიღი"
                                                    format="dd/MM/yyyy"
                                                />
                                                </MuiPickersUtilsProvider>
                                                <span className="error">{birthDateError}</span>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                // required
                                                fullWidth
                                                error = {phoneError.length > 0 ? true : false}
                                                id="phone"
                                                placeholder="+995599123456"
                                                value={this.state.phone}
                                                onChange={e => this.inputChange("phone",e.target.value)}
                                                label="მობილურის ნომერი"
                                                name="phone"
                                            />
                                            <span className="error">{phoneError}</span>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                // required
                                                error = {emailError.length > 0 ? true : false}
                                                fullWidth
                                                id="email"
                                                value={this.state.email}
                                                onChange={e => this.inputChange("email",e.target.value)}
                                                label="ელ.ფოსტა"
                                                name="email"
                                            />
                                            <span className="error">{emailError}</span>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="city"
                                                name="city"
                                                error = {cityError.length > 0 ? true: false}
                                                select
                                                label="ქალაქი/რაიონი"
                                                fullWidth
                                                onChange={this.handleCityChange}
                                                value={this.state.city}
                                                variant="outlined"
                                            >
                                                {this.state.cities.map(option => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}

                                            </TextField>
                                            <span className="error">{cityError}</span>
                                        </Grid>
                                        <Grid item xs={12} sm={6}  className="schollHolder">
                                            <TextField
                                                id="school"
                                                name="school"
                                                error = {schoolError.length > 0 ? true : false}
                                                select
                                                label="სკოლა"
                                                className = "some1"
                                                fullWidth
                                                onChange={this.handleSchoolChange}
                                                value={this.state.school}
                                                variant="outlined"
                                            >
                                                {this.state.schools.map(option => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            {this.state.city ? <></> :<div className="schollOver" onClick = {this.checkSchools}></div>}
                                            {/* <div className="schollOver" onClick = {this.checkSchools}></div> */}
                                            <span className="error">{schoolError}</span>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="grade"
                                                label="კლასი"
                                                name="grade"
                                                variant="outlined"
                                                error = {gradeError.length > 0 ? true : false}
                                                select
                                                // required
                                                fullWidth
                                                value={this.state.grade}
                                                onChange={e => this.inputChange("grade",e.target.value)}
                                            >
                                                {grades.map(option => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <span className="error">{gradeError}</span>
                                        </Grid>
                                        {noAccount ? (
                                            <>
                                        <Grid item xs={12} sm={6}>
                                            <div className="form-group">
                                                <input
                                                    name="checkbox"
                                                    type="checkbox"
                                                    onChange={e => this.inputChange("checkbox",e.target.checked)}
                                                    id="checkbox"
                                                    // required
                                                />
                                                <span className="privacy" onClick={this.openPrivacyPolicy}>
                                                    ვეთანხმები წესებს და პირობებს
                                                </span>
                                            </div>
                                        </Grid>
                                            </>
                                        ) : (
                                            <></>
                                        )}


                                    </Grid>
                                    <Typography className={classes.submitWrapper} >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        ref = {this.submitButton}
                                    >
                                        {noAccount ? "რეგისტრაცია" : "შენახვა"}
                                    </Button>
                                    </Typography>
                                </form>
                            </div>
                        </Container>
                    </main>
                </Container>
                <Footer/>
            </React.Fragment>
        );
    }
}
ManageProfilePage.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ManageProfilePage);
