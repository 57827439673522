import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { toast } from 'react-toastify';
import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
// imports from Amplify library
import { Auth } from 'aws-amplify';
//import { /*listCitys,,listStudents*/, getSettings} from "../../graphql/queries";
import Link from '@material-ui/core/Link'

const styles = (theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        textAlign: "center"
    },
    submitWrapper: {
        margin: theme.spacing(3, 0, 2),
        textAlign: "center"
    },
    submit: {
        width : "42%"
    }
}));




class ResetPassword extends React.Component {


    inputChange(state,value){
        this.setState({
        [state]: value
        });
    }


    

    handleSubmit = async  (event) => {
        event.preventDefault();

        if (this.state.userName.length !== 11) {
            toast.error("პირადი ნომერი არასწორია");
            return;
        }

        if(!this.state.codeSent){
            try {
                let a = await Auth.forgotPassword(this.state.userName);
                let email = "";
                if(a && a.CodeDeliveryDetails && a.CodeDeliveryDetails.Destination)
                    email = a.CodeDeliveryDetails.Destination;
                toast.success("კოდი წარმატებით გაიგზავნა ელ.ფოსტაზე: " + email);
                this.setState({ codeSent: true });
            } catch (e) {
                alert(e.message);
            }
        }else{
            try {
             await Auth.forgotPasswordSubmit(
                    this.state.userName,
                    this.state.code,
                    this.state.password
                );
                toast.success("პაროლი წარმატებით შეიცვალა!");
                await Auth.signIn(this.state.userName, this.state.password);
                window.location.href = "/profile";
            } catch (e) {
                if(e && e.message && e.message.indexOf("code")!==-1){
                    toast.error("კოდი არასწორია");
                }else{
                    toast.error("პაროლის სიგრძე უნდა იყოს მინიმუმ 6 სიმბოლო");
                    alert(e.message);
                }
            }
        }
      }

    constructor(props) {
        super(props);
        this.state = {
            userName: "",
            password : "",
            code : "",
            codeSent : false,
            noAccount : false
        }
    }



    render(){
        const { classes } = this.props;
        

        return (
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header active="/profile"/>
                    <Typography variant="h6" gutterBottom align="center">
                        პაროლის აღდგენა
                    </Typography>
                    <Divider/>
                    <main>
                        <Container component="main" maxWidth="lg">
                            <CssBaseline />
                            <div className={classes.paper}>
                                <form className={classes.form} onSubmit={this.handleSubmit}>
                                    <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12}>
                                                    <div className="loginInput">
                                                    <TextField
                                                        autoComplete="username"
                                                        name="userName"
                                                        variant="outlined"
                                                        required
                                                        style={{"width":"42%"}}
                                                        value={this.state.userName}
                                                        onChange={e => this.inputChange("userName",e.target.value)}
                                                        id="userName"
                                                        label="პირადი ნომერი"
                                                    />
                                                    </div>
                                                </Grid>
                                        {this.state.codeSent ?(<>
                                            <Grid item xs={12} sm={12}>
                                                <div className="loginInput">
                                                    <TextField
                                                        name="code"
                                                        variant="outlined"
                                                        required
                                                        style={{"width":"42%"}}
                                                        value={this.state.code}
                                                        onChange={e => this.inputChange("code",e.target.value)}
                                                        id="code"
                                                        label="ელ.ფოსტაზე მიღებული კოდი"
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                            <div className="loginInput">
                                            <TextField
                                            autoComplete="password"
                                            name="password"
                                            type="password"
                                            variant="outlined"
                                            required
                                            style={{"width":"42%"}}
                                            value={this.state.password}
                                            onChange={e => this.inputChange("password",e.target.value)}
                                            id="password"
                                            label="ახალი პაროლი"
                                            />
                                            </div>
                                            </Grid>
                                            </>
                                        ):(<></>
                                            /*<Grid item xs={12} sm={12}>
                                                <Link className={classes.submit} href="/login">
                                                    <Typography
                                                        variant="body1"
                                                        color="inherit"
                                                        align="left"
                                                        noWrap
                                                        style={{"width": "42%", "margin": "0 auto"}}
                                                    >
                                                        შესვლა
                                                    </Typography>
                                                </Link>
                                            </Grid>*/)
                                        }
                                    </Grid>
                                    <Typography className={classes.submitWrapper} >
                                        {!this.state.codeSent ?(
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        პაროლის ელ.ფოსტაზე გაგზავნა
                                    </Button>):(
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className={classes.submit}
                                            >
                                                ახალი პაროლის შენახვა
                                            </Button>
                                        )}
                                    </Typography>
                                </form>
                            </div>
                        </Container>
                    </main>
                </Container>
                <Footer/>
            </React.Fragment>
        );
    }
}
ResetPassword.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ResetPassword);
