import React, { Component } from 'react';

class QuizTimer extends Component {
    state={
        minutes: -1,
        seconds : 0
    }

    componentDidMount(){
        this.setState({minutes: this.props.time});

        this.myInterval = setInterval(() => {
            const { seconds, minutes } = this.state

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval)
                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            } 
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }   

    render() {
        const {minutes, seconds} = this.state
        return (
            <div className="timer-holder">
                { minutes === 0 && seconds === 0
                    ? (clearInterval(this.myInterval), 'დრო ამოიწურა')
                    : <span className={`minutes ${minutes < 1 ? 'danger' : ''}` }> დარჩენილი დრო :  {minutes < 10 ? `0${minutes}`: minutes} : {seconds < 10 ? `0${seconds}` : seconds}</span>
                }

            </div>
        );
    }
}

export default QuizTimer;