import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Header from '../common/Header';
import Footer from '../common/Footer';

import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography";
import { API } from 'aws-amplify';
import {getSiteSetting} from "../../graphql/queries";



class PaymentPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            content : "",
            title : ""
        }
    }

    async componentDidMount() {
        //console.log(Storage.get('IMG_1234.JPG',{ level: 'public' }));
        try {
            const aboutUs = await API.graphql({query:getSiteSetting,variables:{"id":"payment"},authMode:"API_KEY"});

            let json = JSON.parse(aboutUs.data.getSiteSetting.value);
            this.setState({content: json.content});
            this.setState({title: json.title});

        } catch (err) {
            console.log('error fetching settings...', err)
        }
    }


    render(){

        return (
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header/>
                    <Typography variant="h6" gutterBottom align="center">
                        {this.state.title}
                    </Typography>
                    <Divider/>
                    <main>
                        <Typography dangerouslySetInnerHTML={{__html:  this.state.content}}></Typography>
                    </main>
                </Container>
                <Footer/>
            </React.Fragment>
        );
    }
}

export default PaymentPage;
