import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import List from 'react-content-loader'
import Container from '@material-ui/core/Container';
import Header from '../common/Header';
import FeaturedPost from '../blog/FeaturedPost';
import MainCarousel from '../blog/MainCarousel';
import Footer from '../common/Footer';

// imports from Amplify library
import { API } from 'aws-amplify';

// import query definition
import { getBlog as GetBlog, getSiteSetting } from '../../graphql/queries'
  

class MainPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = { featuredPosts: [] , slides: []}
  }
  // execute the query in componentDidMount
  async componentDidMount() {
    //console.log(Storage.get('IMG_1234.JPG',{ level: 'public' }));
    try {
      const blogs = await API.graphql({query:GetBlog,variables:{"id":"news-id"},authMode:"API_KEY"});
      
      console.log('posts:', blogs.data.getBlog.posts.items)
      var posts = blogs.data.getBlog.posts.items.map(function(el){
        el.description = el.content;
        el.date = el.updatedAt.toString();
        el.image = el.image ? el.image : "https://kslides.s3.eu-central-1.amazonaws.com/K-thumb1_web.jpg";
        el.imageText = "Image Text";
        return el;
      }).slice(0,3);
      this.setState({featuredPosts: posts});
      // this.setState({
      //   posts: blogs.data.listBlogs.items
      // })
    } catch (err) {
      console.log('error fetching blogs...', err)
    }
    try {
      const aboutUs = await API.graphql({query:getSiteSetting,variables:{"id":"slider"},authMode:"API_KEY"});

      let json = JSON.parse(aboutUs.data.getSiteSetting.value);
      this.setState({slides: json.slides});

    } catch (err) {
      console.log('error fetching slides...', err)
    }
  }

  render(){
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg">
          <Header/>
          <main>
            {this.state.slides.length ? (<MainCarousel slides={this.state.slides}/>):(<List/>)}
            {this.state.featuredPosts.length ? (
                   <Grid container spacing={4}>
                   {this.state.featuredPosts.map((post,index) => (
                     <FeaturedPost key={index} post={post} />
                   ))}
                 </Grid>
              ) : (
              <List/>
              )}
          </main>
        </Container>
        <Footer/>
      </React.Fragment>
    );
  }
}
export default MainPage;
