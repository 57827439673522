import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { toast } from 'react-toastify';
import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
// imports from Amplify library
import { Auth } from 'aws-amplify';
//import { /*listCitys,,listStudents*/, getSettings} from "../../graphql/queries";
import Link from '@material-ui/core/Link'

const styles = (theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        textAlign: "center"
    },
    submitWrapper: {
        margin: theme.spacing(3, 0, 2),
        textAlign: "center"
    },
    submit: {
        width : "42%"
    }
}));




class LoginPage extends React.Component {


    inputChange(state,value){
        this.setState({
        [state]: value
        });
    }

    handleCityChange = (event) => {
        this.setState({ city: event.target.value });
        this.setState({ schools: this.state.cities[this.state.cities.map(function(x) {return x.id; }).indexOf(event.target.value)].schools.items.sort((a, b) => a.name > b.name ? 1 : -1) });
    };

    handleSchoolChange = (event) => {
        this.setState({ school: event.target.value });
    };

    

    handleSubmit = async  (event) => {
        event.preventDefault();


            if (this.state.password.length < 6) {
                toast.error("პაროლი არასწორია");
                return;
            }
            let obj = {
                username: this.state.userName, email: this.state.email, password: this.state.password
            };
            const {username, password} = obj;
            try {
                await Auth.signIn(username, password)
                console.log('user successfully signed In');
                window.location.href = "/profile"
            }catch (e) {
                if(e && e.message && e.message.indexOf("not exist")!==-1){
                    toast.error("ასეთი მომხმარებელი არ არსებობს");
                }else
                    toast.error("არასწორი პირადი ნომერი ან პაროლი");
                //toast.error(e.message || "Error Signing In");
                console.log(e);
            }
      }

    constructor(props) {
        super(props);
        this.state = {
            userName: "",
            password:"",
            noAccount : false
        }

        Auth.currentAuthenticatedUser().then(user=>{
            window.location.href="/profile"
        }).catch(err => {
            console.log(err);
        });
    }



    render(){
        const { classes } = this.props;
        

        return (
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header active="/profile"/>
                    <Typography variant="h6" gutterBottom align="center">
                        ჩემი პროფილი
                    </Typography>
                    <Divider/>
                    <main>
                        <Container component="main" maxWidth="lg">
                            <CssBaseline />
                            <div className={classes.paper}>
                                <form className={classes.form} onSubmit={this.handleSubmit}>
                                    <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12}>
                                                    <div className="loginInput">
                                                    <TextField
                                                        autoComplete="username"
                                                        name="userName"
                                                        variant="outlined"
                                                        required
                                                        style={{"width":"42%"}}
                                                        value={this.state.userName}
                                                        onChange={e => this.inputChange("userName",e.target.value)}
                                                        id="userName"
                                                        label="პირადი ნომერი"
                                                    />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                <div className="loginInput">
                                                    <TextField
                                                        name="password"
                                                        type="password"
                                                        autoComplete="password"
                                                        variant="outlined"
                                                        minLength={8}
                                                        style={{"width":"42%"}}
                                                        required
                                                        value={this.state.password}
                                                        onChange={e => this.inputChange("password",e.target.value)}
                                                        id="password"
                                                        label="პაროლი"
                                                    />
                                                    </div>
                                                </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Link  className={classes.submit} href="/reset-password">
                                                <Typography
                                                    variant="body1"
                                                    color="inherit"
                                                    align="left"
                                                    noWrap
                                                    style={{"width":"42%","margin":"0 auto"}}
                                                >
                                                    დაგავიწყდათ პაროლი?
                                                </Typography>
                                            </Link>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Link  className={classes.submit} href="/edit-profile">
                                                <Typography
                                                    variant="body1"
                                                    color="inherit"
                                                    align="left"
                                                    noWrap
                                                    style={{"width":"42%","margin":"0 auto"}}
                                                >
                                                    რეგისტრაცია
                                                </Typography>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    <Typography className={classes.submitWrapper} >

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        შესვლა
                                    </Button>
                                    </Typography>
                                </form>
                            </div>
                        </Container>
                    </main>
                </Container>
                <Footer/>
            </React.Fragment>
        );
    }
}
LoginPage.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(LoginPage);
